import firstImage from './assets/images/firstimage.jpg';
import secondImage from './assets/images/second.jpg'; 

export const posts = [
  {
    id: 1,
    title: "YMS MOVE",
    img: firstImage
   },
  {
    id: 2,
    title: "WMS MOVE",
    img: secondImage
    },
   {
    id: 3,
    title: "KMS MOVE",
    img: secondImage
    }, 
];
