
import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Loader from "./components/Loading/Loader";
import "./App.css";
import Home from "./pages/Home/Home";
import Post from "./pages/Post/Post";
import Login from "./pages/Login/Login";
import Form from "./pages/Forms/Form";
import Department from "./pages/Departments/Department";
import Blankpage from "./pages/Blankpage/Blankpage";
import Alert from "./pages/AlertBox/Alert";

const App = () => {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
};

const AppContent = () => {
  const location = useLocation();
  const showNavbar = location.pathname !== "/";
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = () => setLoading(true);
    const handleComplete = () => setLoading(false);

    handleStart();
    const timer = setTimeout(() => {
      handleComplete();
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [location]);

  return (
    <div>
      {showNavbar && <Navbar />}
      {loading && <Loader />}
      {!loading && (
        <Routes>
          <Route path="/home" element={<Department />} />
          <Route path="/" element={<Login />} />
          <Route path="/post/:id" element={<Post />} />
          <Route path="/dataform" element={<Form />} />
          <Route path="/ymc" element={<Home />} />
          <Route path="/nodata" element={<Blankpage />} />
          <Route path="/alert" element={<Alert />} />
        </Routes>
      )}
    </div>
  );
};

export default App;
