import React from 'react';
import './Alert.css';
import { Button, Modal } from 'react-bootstrap';

const Alert = ({ show, handleClose }) => {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title><span className='oops'>OOps!!!</span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                You're not authorized to access this page....
                <br /><br />
                Contact this email for request: <span className='contact'>ymsservice@gmail.com</span>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default Alert;



















