import React from 'react';
import Card from "../../components/Home_Card/Home_card";
import { posts } from "../../data";
import { useNavigate } from 'react-router-dom';
import { TbArrowBackUpDouble } from "react-icons/tb";
import "./Home.css";

const Home = () => {
    const navigate = useNavigate();

    const handleClick = (postId) => {
        switch (postId) {
            case 1:
                navigate("/dataform");
                break;
            case 2:
            case 3:
                navigate("/nodata");
                break;
            default:
                break;
        }
    };

    const handleIconClick = () => {
        navigate("/home");
    };

    return (
        <div>
            <TbArrowBackUpDouble className='icon' onClick={handleIconClick} />
            <div className="home">
                {posts.map(post => (
                    <Card
                        key={post.id}
                        post={post}
                        onClick={() => handleClick(post.id)}
                    />
                ))}
            </div>
        </div>
    );
};

export default Home;
