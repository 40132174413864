import React, { useState } from 'react';
import Dept_card from "../../components/Dept_Card/Dep_card";
import { CardData } from "../../card_data";
import "./Department.css";
import { useNavigate } from 'react-router-dom';
import Alert from '../AlertBox/Alert';

const Department = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    const handleClick = (postId) => {
        if (postId === 2) {
            navigate("/ymc");
        } else {
            handleShowModal();
        }
    };

    const activePostId = 2;

    return (
        <div>
            <h2>Department</h2>
        
        <div className="dept_home">
            {CardData.map(card => (
                <Dept_card
                    key={card.id}
                    CardData={card}
                    isActive={card.id === activePostId}
                    onClick={() => handleClick(card.id)}
                />
            ))}
            <Alert show={showModal} handleClose={handleCloseModal} />
        </div>
        </div>
    );
}

export default Department;




















/* import React, { useState } from 'react';
import Card from "../../components/Dept_Card/Dep_card";
import { CardData } from "../../card_data";
import "./Department.css";
import { useNavigate } from 'react-router-dom';
import Alert from '../AlertBox/Alert';

const Department = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    const handleClick = (postId) => {
        switch (postId) {
            /* case 2:
                navigate("/ymc");
                break; 
            case 2:
                handleShowModal();
                break;
            default:
                break;
        }
    };

    const activePostId = 2;
    return (
        <div className="dept_home">
            {CardData.map(card => (
                <Card
                    key={card.id}
                    CardData={card}
                    isActive={card.id === activePostId}
                    onClick={() => handleClick(card.id)}
                />
            ))}
            <Alert show={showModal} handleClose={handleCloseModal} />
        </div>
    );
}

export default Department;
 */