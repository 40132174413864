import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { TbArrowBackUpDouble } from "react-icons/tb";
import "./Form.css";

const columns = [
  { field: 'id', headerName: 'ID', width: 30 },
  { field: 'company', headerName: 'COMPANY', width: 90 },
  { field: 'item', headerName: 'ITEM', width: 60 },
  { field: 'lot', headerName: 'LOT', type: 'number', width: 30 },
  { field: 'on_hand_qty', headerName: 'ON HAND QTY', type: 'number', width: 120 },
  { field: 'exp_date', headerName: 'EXPIRATION DATE', width: 150 },
  { field: 'inventory_sts', headerName: 'INVENTORY_STS', width: 150 },
  { field: 'item_desc', headerName: 'ITEM_DESC', width: 100 },
  { field: 'logistic', headerName: 'LOGISTIC', width: 80 },
];

const rows = [
  { id: 1, company: 'LMT01', item: '22117', lot: 35, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 2, company: 'LMT01', item: '22117', lot: 42, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 3, company: 'LMT01', item: '22117', lot: 45, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 4, company: 'LMT01', item: '22117', lot: 16, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 5, company: 'LMT01', item: '22117', lot: 16, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 6, company: 'CEL', item: '22117', lot: 150, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 7, company: 'CEL', item: '22117', lot: 44, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 8, company: 'CEL', item: '22117', lot: 36, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 9, company: 'CEL', item: '22117', lot: 65, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 10, company: 'LMT01', item: '22117', lot: 16, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 11, company: 'CEL', item: '22117', lot: 150, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 12, company: 'CEL', item: '22117', lot: 44, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 13, company: 'CEL', item: '22117', lot: 36, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 14, company: 'CEL', item: '22117', lot: 65, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
];

const StyledDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#007BFF',
    color: 'white',
  },
});

const Form = () => {
  const [showTable, setShowTable] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [formData, setFormData] = useState({
    trailerId: '',
    toBlg: '',
    fromBlg: '',
    loadType: '',
    campaign: '',
    comment: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowTable(true);
    if (formData.trailerId && formData.toBlg && formData.fromBlg && formData.loadType) {
      showAlert();
    }
  };

  const handleProcess = () => {
    setDownloading(true);
    setTimeout(() => {
      window.location.href = '/ymc';
    }, 3000);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/ymc");
  };

  const showAlert = () => {
    Swal.fire({
      title: 'Alert ',
      text: 'Data Validated!',
      icon: 'warning',
      confirmButtonText: 'OK',
    });
  };

  return (
    <div>
      <TbArrowBackUpDouble className='icon-back' onClick={handleClick} />
      <div className="containers">
        <div className={`form ${showTable ? 'expanded' : ''}`}>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="trailerId">Trailer ID:<span className='star-icon'>*</span></label>
              <input type="text" id="trailerId" name="trailerId" value={formData.trailerId} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="toBlg">TO BLG:<span className='star-icon'>*</span></label>
              <input type="text" id="toBlg" name="toBlg" value={formData.toBlg} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="fromBlg">From BLG:<span className='star-icon'>*</span></label>
              <input type="text" id="fromBlg" name="fromBlg" value={formData.fromBlg} onChange={handleChange} required />
            </div>
            <div className='dropd'>
              <label htmlFor="loadType">Load Type:<span className='star-icon'>*</span></label>
              <select id="loadType" name="loadType" value={formData.loadType} onChange={handleChange} required>
                <option value="">Select Type</option>
                <option value="finished goods">Finished Goods</option>
                <option value="can bodies">Can Bodies</option>
                <option value="raw materials">Raw Materials</option>
                <option value="pallets">Pallets</option>
              </select>
            </div>
            <div>
              <label htmlFor="campaign">Campaign:</label>
              <input type="text" id="campaign" name="campaign" value={formData.campaign} onChange={handleChange} />
            </div>
            <div>
              <label htmlFor="comment">Comment:</label>
              <textarea id="comment" name="comment" value={formData.comment} onChange={handleChange}></textarea>
            </div>
            <button type="submit">Validate</button>
            {showTable && (
              <button className="proceedButton" onClick={handleProcess}>
                {downloading ? 'Downloading...' : 'Process'}
                {downloading && <div className="progress"></div>}
              </button>
            )}
          </form>
        </div>
        <div className={`form_table ${showTable ? 'expanded' : ''}`}>
          {showTable ? (
            <div style={{ height: 400, width: '100%' }}>
              <StyledDataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
              />
            </div>
          ) : (
            <img src="https://img.freepik.com/premium-vector/no-data-found_585024-42.jpg" alt="No Data" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Form;



/* import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { TbArrowBackUpDouble } from "react-icons/tb";
import "./Form.css";

const columns = [
  { field: 'id', headerName: 'ID', width: 30 },
  { field: 'company', headerName: 'COMPANY', width: 90 },
  { field: 'item', headerName: 'ITEM', width: 60 },
  {
    field: 'lot',
    headerName: 'LOT',
    type: 'number',
    width: 30,
  },
  {
    field: 'on_hand_qty',
    headerName: 'ON HAND QTY',
    type: 'number',
    width: 120,
  },
  { field: 'exp_date', headerName: 'EXPIRATION DATE', width: 150 },
  { field: 'inventory_sts', headerName: 'INVENTORY_STS', width: 150 },
  { field: 'item_desc', headerName: 'ITEM_DESC', width: 100 },
  { field: 'logistic', headerName: 'LOGISTIC', width: 80 },
];

const rows = [
  { id: 1, company: 'LMT01', item: '22117', lot: 35, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 2, company: 'LMT01', item: '22117', lot: 42, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 3, company: 'LMT01', item: '22117', lot: 45, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 4, company: 'LMT01', item: '22117', lot: 16, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 5, company: 'LMT01', item: '22117', lot: 16, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 6, company: 'CEL', item: '22117', lot: 150, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 7, company: 'CEL', item: '22117', lot: 44, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 8, company: 'CEL', item: '22117', lot: 36, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 9, company: 'CEL', item: '22117', lot: 65, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 10, company: 'LMT01', item: '22117', lot: 16, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 11, company: 'CEL', item: '22117', lot: 150, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 12, company: 'CEL', item: '22117', lot: 44, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 13, company: 'CEL', item: '22117', lot: 36, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
  { id: 14, company: 'CEL', item: '22117', lot: 65, on_hand_qty: 654897, exp_date: '2024-03-26 0.0.0', inventory_sts: 'Available', item_desc: 'LMNT ,16', logistic: '21548996' },
];

const StyledDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#007BFF',
    color: 'white',
  },
});

const Form = () => {
  const [showTable, setShowTable] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [formData, setFormData] = useState({
    trailerId: '',
    toBlg: '',
    fromBlg: '',
    loadType: '',
    campaign: '',
    comment: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowTable(true);
    if (formData.trailerId && formData.toBlg && formData.fromBlg && formData.loadType) {
      showAlert();
    }
  };

  const handleProcess = () => {
    setDownloading(true);
    setTimeout(() => {
      window.location.href = '/ymc';
    }, 3000);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/ymc");
  };

  const showAlert = () => {
    Swal.fire({
      title: 'Alert ',
      text: 'Data Validated!',
      icon: 'warning',
      confirmButtonText: 'OK',
    });
  };


  

  return (
    <div>

      <TbArrowBackUpDouble className='icon-back' onClick={handleClick} />

      <div className="containers">
        <div className="form">
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="trailerId">Trailer ID:<span className='star-icon'>*</span></label>
              <input type="text" id="trailerId" name="trailerId" value={formData.trailerId} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="toBlg">TO BLG:<span className='star-icon'>*</span></label>
              <input type="text" id="toBlg" name="toBlg" value={formData.toBlg} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="fromBlg">From BLG:<span className='star-icon'>*</span></label>
              <input type="text" id="fromBlg" name="fromBlg" value={formData.fromBlg} onChange={handleChange} required />
            </div>
            <div className='dropd'>
              <label htmlFor="loadType">Load Type:<span className='star-icon'>*</span></label>
              <select id="loadType" name="loadType" value={formData.loadType} onChange={handleChange} required>
                <option value="">Select Type</option>
                <option value="finished goods">Finished Goods</option>
                <option value="can bodies">Can Bodies</option>
                <option value="raw materials">Raw Materials</option>
                <option value="pallets">Pallets</option>
              </select>
            </div>
            <div>
              <label htmlFor="campaign">Campaign:</label>
              <input type="text" id="campaign" name="campaign" value={formData.campaign} onChange={handleChange} />
            </div>
            <div>
              <label htmlFor="comment">Comment:</label>
              <textarea id="comment" name="comment" value={formData.comment} onChange={handleChange}></textarea>
            </div>
            <button type="submit">Validate</button>
            {showTable && (
              <button className="proceedButton" onClick={handleProcess}>
                {downloading ? 'Downloading...' : 'Process'}
                {downloading && <div className="progress"></div>}
              </button>
            )}
          </form>
        </div>
        <div className="form_table">
          {showTable ? (
            <div style={{ height: 400, width: '100%' }}>
              <StyledDataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
              />
            </div>
          ) : (
            <img src="https://img.freepik.com/premium-vector/no-data-found_585024-42.jpg" alt="No Data" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Form;
 */