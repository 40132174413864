import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import "./Navbar.css";

const user = {
  id: 1,
  displayName: "John Doe",
  img: "https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
};

const Navbar = () => {

  const navigate=useNavigate();


   const showSignout = () => {
    Swal.fire({
      title: 'Logout',
      text: 'Are you sure you want to logout?',
      icon: 'warning',
      confirmButtonText: 'OK',
      showCancelButton: true, 
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        navigate('/');
      }
    });
  };
  return (
    <div className="navbar">
      <span className="logo">
        <Link className="link" to="/home">
          YMS APP
        </Link>
      </span>
      <ul className="list">
        <li className="listItem listItem-home">
          <Link className="link" to="/home">Home</Link>
        </li>
        <li className="listItem listItem-img">
          <img
            src={user.img}
            alt=""
            className="avatar"
          />
        </li>
        <li className="listItem listItem-name">{user.displayName}</li>
        <li className="listItem listItem-logout">
          <Link  className="link logout-text" onClick={showSignout}>
            Logout
          </Link>
          <Link onClick={showSignout} className="link logout-icon">
            <i className="fas fa-sign-out-alt"></i>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
