import React from 'react';
import { Link } from 'react-router-dom';
import './Home_card.css';

const Card = ({ post, onClick }) => {
  return (
    <div className="card" onClick={onClick}>
      <Link className="link" to={post.id === 1 ? "/ymc" : `/post/${post.id}`}>
        <img src={post.img} alt={post.title} className="img" />
        <div className="card-content">
          <img src={post.img} alt={post.title} className="small-img" />
          <span className="title">{post.title}</span>
        </div>
      </Link>
    </div>
  );
};

export default Card;
