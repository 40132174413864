import React from 'react';
import './Dep_card.css';

const Dept_card = ({ CardData, isActive, onClick }) => {
  return (
    <div className='dept_main'>
      <h5>{CardData.title}</h5>
      <div 
        className={`img_cards ${isActive ? 'active' : 'disabled'}`} 
        onClick={onClick}
      >
        <div className="card_link">
          <img src={CardData.img} alt={CardData.title} className="images" />
        </div>
      </div>
    </div>
  );
};

export default Dept_card;


















/* import React from 'react';
import './Dep_card.css';

const Dept_card = ({ CardData, isActive, onClick }) => {
  return (
    <div className='dept_main'>
      <h5>{CardData.title}</h5>
      <div className={`img_cards ${isActive ? 'active' : 'disabled'}`} onClick={onClick}>
        <div className="card_link">
          <img src={CardData.img} alt={CardData.title} className="images" />
        </div>
      </div>
    </div>

  );
};

export default Dept_card;
 */