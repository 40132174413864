import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login = () => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/home");
  };
  return (
    <div className="container">
      <div className="login">
        <h2>LOGIN</h2>
        <div>
          <div className="inputBox">
            <input type="text" autoFocus="autofocus" placeholder="Email" />
          </div>
          <div className="inputBox">
            <input type="password" placeholder="Password" />
          </div>

          <div className="buttons">
            <button type="submit" className="login-but" onClick={handleClick}>
              Login
            </button>
            <div className="or-line">
              <hr />
              <span>Or</span>
              <hr />
            </div>

            <a className="microsoft-button" href="https://login.microsoftonline.com/common/oauth2/v2.0/authorize?scope=service%3A%3Aaccount.microsoft.com%3A%3AMBI_SSL+openid+profile+offline_access&response_type=code&client_id=81feaced-5ddd-41e7-8bef-3e20a2689bb7&redirect_uri=https%3A%2F%2Faccount.microsoft.com%2Fauth%2Fcomplete-signin-oauth&client-request-id=01effc51-9b27-47b5-a814-e32c89094896&x-client-SKU=MSAL.Desktop&x-client-Ver=4.58.1.0&x-client-OS=Windows+Server+2019+Datacenter&prompt=login&client_info=1&state=H4sIAAAAAAAEAA3MyYJDMAAA0H-Zq4NSrTr0kBIl1BJLxS2qlNq3wdfPvA94P2Iqoeixaau0nbETmsPsZqV1tli2uhEHFdwcMXQabEncO3elPStGXdtwOlLFAUgrfkxh0yQVY_PHu4MvSizdntnEqrl0QaqeGwkjhAYZ7m1vH0YyBu24F-U51hvnxLg5sWL0n99XDHAnnaqaKwHpvtLou3oa4qISSfEAKiw9FFRGxY_uioiy9LydPWGiJB_VKCjdLZjCWv6yh71FSVs7bwgHnX6CzucMUTNz038FihPmc2ntS7UMtUcSfw4ZLbfSLveNJEPHNugPij6TSIgLUd4EfrhsKIuPJMLY-q1vng8Ue_EdqkEiT0POcvjlfkySPul79MB3hycBvBivlzMKrtefP8amZXhaAQAA&msaoauth2=true&lc=1033">
              <img src="https://img.icons8.com/color/48/000000/microsoft.png" alt="no image" />
              SSO Login with Microsoft
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
