
import React from "react";
import "./Blankpage.css";
import { useNavigate } from "react-router-dom";
import Nodata from "../../assets/images/no_data.png"; 

const Blankpage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/ymc");
  };

  return (
    <div className="blankpage-container">
      <div className="image-container">
        <img src={Nodata} alt="No Data" className="blankpage-image" />
        <button className="back-to-home" onClick={handleClick}>BACK</button>
      </div>
    </div>
  );
};

export default Blankpage;
